.job-detail-wrapper {
    background-color: #232323;
}

.apply-btns {
    background-color: #27E646;
    padding: 8px 16px;
    border: none;
    transition: opacity 0.3s ease-in-out;
    cursor: none;
}

.jd-column-two {
    text-align: justify;
}

.apply-btns-container {
    gap: 5%;
    margin-right: 13%;
    padding-bottom: 10%;
}

@media (max-width: 769px) {
    .jd-column-two {
        margin-top: 7%;
    }
}

.job-titles {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

@media (min-width: 768px) and (max-width: 991px) {
    .title-job-desc {
        display: flex !important;
        flex-direction: column !important;
    }

    .apply-btns-container{
        justify-content: flex-start !important;
        margin-left: 2%;
    }
}


@media (min-width: 2520px) {
    .apply-btns-container {
        margin-right: 18%;
    }
}


@media (min-width: 2560px) {
    .apply-btns-container {
        margin-right: 18%;
    }
}