.image-container {
  position: relative;
}

.image-container img {
  object-fit: cover;
}

.img-div-kfir {
  position: relative;
}


.flex-blog-item {
  position: relative;
}

.blogs-margin {
  margin-top: 6%;
}


.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-blog-item:hover .image-overlay {
  opacity: 1;
}

.text-bold {
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
}

.case-title-two {
  color: white;
}




@media (max-width: 992px) {
  .flex-blog-item {
    position: relative;

  }
  .img-div-kfir{
margin-bottom: 2%;
  }
  .text-blog-by{
    font-size: 14px;
  }
}
.text-blog-by{
  font-size: 16px;
}

/* MOBILE */
@media (min-width: 320px) and (max-width: 767px) {

  .image-container {
    justify-content: center;
    position: relative;
  }

  .image-container div img {
    text-align: center;
  }

}
