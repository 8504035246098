.item-bd {
    max-width: 80%;
    margin: 0 auto;
    text-align: justify;
  }
  
  .item-bd img {
    max-width: 100%;
    margin-bottom: 15%;
  }

  .blogger-details {
    line-height: 0.7rem;
  }
  


  /* @media (min-width: 768px) {
    .share-it-mobile {
      display: none;
    }
  } */
  