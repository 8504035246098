
  .banner-img-section  .swiper-slide{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
 

  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
 