@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;

}


.nav-elements ul {
  display: flex;
  list-style-type: none;
}
.navlink span{
  font-size: 24px;
  font-weight: 400;
  color: white;
}

.nav-elements ul a {
  font-weight: 400;
  color: white;
  text-decoration: none;
}
.box-inner li {
  font-size: 26px;
}
.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}
.box-inner li a{
  color: white !important;
  font-size: 20px;
}
.nav-elements {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #5642C2;
  clip-path: circle(5px at calc(120% - 44.8px) 45px);
  transition: all 0.3s ease-in-out;
  }


  .wrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #5642C2;
    clip-path: circle(25px at calc(120% - 44.8px) 45px);
    transition: all 0.3s ease-in-out;
  }

  .wrapper > ul {
    font-size: 0.9rem;
    padding-left: 0;
  }

  .wrapper > ul > li > a {
    font-size: 22px !important;
    width: 100%;
  }
  
  #active:checked ~ .wrapper{
    clip-path: circle(75%);
  }
  @media (min-width: 230px) {
    .menu-icon {
      display: block;
    }
  
    .nav-elements {
      z-index: 200;
    }
  
    .nav-elements.active {
      top: 0;
    }

    .nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 15px;
    }
    .box-inner ul li a {
      font-size: 16px !important;
    }
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  nav{
    transition:  all 0.3s ;
    height: 12vh;
    display:flex;
  }
  nav.sticky{
background-color: white;

    top: 0;
    z-index: 100;
  }


  /* responsive social media  */

  @media (max-width: 768px) {
    .social-container, .social-grid, .social-hover {
      width: 30px;
      margin-right: 5px;
    }
  }

